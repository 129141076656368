
body {
  margin: 0;
  font-family:"Charlie Display", "poppins",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-weight: 500;
  transition: 0.1s ease;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hubspot-messages-iframe-container {
  color-scheme : light;
}

html[data-color-mode="dark"] {
  --ds-surface: #101118 !important;
}

@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Thin.woff2);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Regular.woff2);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Semibold.woff2);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Bold.woff2);
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Thin_Italic.woff2);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Italic.woff2);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Semibold_Italic.woff2);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Bold_Italic.woff2);
  font-weight: 700;
  font-style: italic;
}

.Typewriter__cursor {
  font-size: 3rem !important;
}
.header-navbar-brand-light{
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 700;
  height: 40px;
  font-family: poppins;
  color: #0d6efd;
}
.header-navbar-brand-dark{
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 700;
  height: 40px;
  font-family: poppins;
  color: white;
}

.header-navbar-light {
  border: 2px solid #dfdcd8;
  background-color: white;
  color: rgba(0,0,0,.55);
  cursor: pointer;
  font-weight: 500;
}
.header-navbar-dark {
  border-bottom: 2px solid #101118;
  background-color: #24222B;
  color: white;
  font-weight: 500;

}


.header-navlink-light {
  color: rgba(0,0,0,.55) !important;
  border: none;
}
.header-navlink-dark {
  color: white !important;
}

.settings-link-light {
  color: rgba(0,0,0,.55) !important;
}
.settings-link-dark {
  color: white !important;
}

.chat-navbar-light {
  border: 1px solid #dfdcd8 !important;
}

.chat-navbar-dark {
  border: 1px solid #101118 !important;
}

.custom-navbar-light {
  position: fixed; /* Keep the sidebar fixed */
  top: 0;
  bottom: 0;
  width: 250px; /* Set the sidebar's width */
  overflow-y: auto; /* Allow scrolling within the sidebar */
  transition: width 0.3s ease;
  margin-top: 50px;
  border: 1px solid #dfdcd8;
  background-color: white;
  color: black;
}

.custom-navbar-dark {
  position: fixed; /* Keep the sidebar fixed */
  top: 0;
  bottom: 0;
  width: 250px; /* Set the sidebar's width */
  overflow-y: auto; /* Allow scrolling within the sidebar */
  transition: width 0.3s ease;
  margin-top: 50px;
  border: 1px solid #24222B;
  background-color: #24222B;
  color: white;
}

.custom-navbar-dark.collapsed {
  width: 80px;
}
.custom-navbar-light.collapsed {
  width: 80px;
}

.custom-navbar-outer {
  width: 300px;
}
.custom-navbar-outer.collapsed {
  width: 80px;
}

.navbar-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  position: absolute; 
  bottom: 0;
  width: 100%;
}

.navbar-items {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  /* font-family: 'Open Sans'; */
}

.navbar-items li {
  padding: 4px 4px;
  display: flex;
  align-items: center;
}

.navbar-items li span.title {
  margin-left: 10px;
}

.side-nav-button-add-chat {
  padding: 10px 25px;
  color: inherit;
  text-decoration: none;
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid rgb(128, 128, 128, 0.3);
}

.side-nav-button-add-chat:hover {
  background-color: #dfdcd8;
  color: black;
}

.side-nav-button-add-chat.active {
  color: white;
  font-weight: 600;
  background-color: #0d6efd !important;
}

.side-nav-button {
  padding: 10px 25px;
  color: inherit;
  text-decoration: none;
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.side-nav-button:hover {
  background-color: #dfdcd8;
  color: black;
}

.side-nav-button.active {
  color: white;
  font-weight: 600;
  background-color: #0d6efd !important;
}

.side-nav-text {
  padding-left: 10px;
  overflow-x: hidden;
  transition: 0.4s ease;
}

.side-nav-title-light {
  /* font-family: 'Open Sans'; */
  font-size: 12px;
  opacity: 0.7;
  color: #333;
  font-weight: 700;
  justify-content: center;
  padding: 10px;
  margin-left: 20px;
}

.side-nav-title-dark {
  /* font-family: 'Open Sans'; */
  font-size: 12px;
  opacity: 0.7;
  color: white;
  font-weight: 700;
  justify-content: center;
  padding: 10px;
  margin-left: 20px;

}

.base-page {
  padding: 20px; 
  width: 100%; 
  transition: width 0.3s ease;
  height: 100%;
}
/* 
.atlassian-select-box {
  z-index: 3002 !important;
} */

.modal-content {
  border: none;
}

.modal-content .form-group {
  padding-bottom: 10px;
}

.select-input-dark {
  background-color: #24222B;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.text-color-dark {
  color: "white"
}

.text-color-light {
  color: "black"
}

.swipe-left {
  animation: swipeLeft 0.4s forwards; /* Animation duration is 0.5s */
}

@keyframes swipeLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}